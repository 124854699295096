export function addImportMap(newMap: { imports: Record<string, string> }) {
  const script = document.createElement('script');
  script.type = 'systemjs-importmap';
  script.textContent = JSON.stringify(newMap);
  document.head.appendChild(script);
  (System as any).prepareImport(true);
}

export function createAppDom(parentName: string, appName: string) {
  const parent = getAppContainer(parentName);
  const app = document.createElement('div');
  app.id = domAppId(appName);
  parent.after(app);
}

export const domAppId = (appName: string) =>
  `single-spa-application:${appName}`;
export const getAppContainer = (appName: string) =>
  document.getElementById(domAppId(appName));
