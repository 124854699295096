import { StateKey, getStateValue } from './state.service';

export const getBasePath = () =>
  // location.pathname.split('/').slice(0, 3).join('/');
  getStateValue<string>(StateKey.MICRO_SHELL_BASE_BATH) ?? ensureBasePath();

const ensureBasePath = () => {
  const microShellPath = '/tarjeta-credito';
  const pathIndex = location.pathname.indexOf(microShellPath);
  return location.pathname.substring(0, pathIndex + microShellPath.length);
};
