import { setBootstrapValues } from './services/state.service';
import { registerApps } from './services/app.service';
import {
  registerBeforeMount,
  unregisterBeforeMount
} from './services/handler.service';
import { ListenerEvents } from './services/listeners-bus.service';
import { validateHistoryState } from './services/listener.routes.service';
import { getBasePath } from './services/location.service';
import { navigateToUrl } from 'single-spa';

const listenerEventBus = ListenerEvents.getInstance();

export async function bootstrap(props) {
  const env = process.env.environment;
  setBootstrapValues(props);
  await listenerEventBus.main(env);
  await registerApps(env as string);
  registerBeforeMount();
  validateHistoryState();
}
export const mount = async () => {
  const basePath = getBasePath();
  const path = window.location.pathname.replace(/\/$/, '');

  if (basePath === path) navigateToUrl(`${basePath}/landing`);
};
export async function unmount(_props) {
  await unregisterBeforeMount();
  listenerEventBus.cleanup();
}
