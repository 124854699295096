import { publishEvent, startListener } from './event-bus.service';

export async function getEventFlagsMock() {
  return new Promise((resolve) => {
    const listenerEventFlags$ = startListener('flagsMockMfe');
    listenerEventFlags$.subscribe((data) => resolve(data));
  });
}

export function publishEventMock(urlMock, topicPublishEvent, nameMfe) {
  const dataMock = {
    url: urlMock,
    topicPublishEvent,
    mfe: nameMfe
  };
  publishEvent('TopicMockMicroShell', dataMock, true);
}
