import { EventBus } from '@avaldigitallabs/adl-commons-lib-frontend-event-bus';
import { Observable } from 'rxjs';
import { EventBusResponse } from '../models/eventbus';
export function startListener<T>(topic: string): Observable<T> {
  const eventBus = EventBus.getInstance();
  const topicListener = eventBus.accessTopic(topic);

  return new Observable((observer) => {
    topicListener.subscribe(
      (data: EventBusResponse<T>) => {
        observer.next(data.topicValue);
      },
      { readStoredMessage: true, onlyOnce: false }
    );
    return () => topicListener.unsubscribe();
  });
}

export function publishEvent<T>(
  topic: string,
  topicValue: T,
  isStored: boolean
): void {
  const eventBus = EventBus.getInstance();
  const topicSender = eventBus.accessTopic(topic);
  topicSender.publish(topicValue, isStored);
}
