import { FetchAdapter } from '../adapters/fetch.adapter.service';
import { environment } from '../environments/environment';
import { HttpService } from './http.service';

export const getMicrofronts = async (env: string): Promise<any> => {
  const httpService = HttpService.getInstance(FetchAdapter.getInstance());
  const url = `${environment.urlAssets[env]}data/config/mfes/${env}/mfes-config.json`;
  const microfronts = await httpService.get(url);
  return microfronts;
};
