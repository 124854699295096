import { registerApplication, unregisterApplication } from 'single-spa';

export function addApplication(appName: string, props?: any) {
  registerApplication(
    appName,
    () => System.import(appName) as any,
    (location) => location.pathname.includes(`/${appName}`),
    props
  );
}

export const importFactory = (appName: string) => () =>
  System.import(appName) as any;
export function removeApplication(appName: string) {
  const app = document.getElementById(`single-spa-application:${appName}`);
  if (app) {
    return unregisterApplication(appName);
  }
}
export const defActivityFn = () => true;
