import { IHttpClient } from '../models/requests';

export class HttpService {
  private http: IHttpClient;
  private static instance: HttpService;

  public static getInstance(adapter: IHttpClient): HttpService {
    if (!HttpService.instance) {
      HttpService.instance = new HttpService(adapter);
    }
    return HttpService.instance;
  }
  private constructor(httpClient: IHttpClient) {
    this.http = httpClient;
  }

  async post<T, Z>(url: string, body: Z): Promise<T> {
    const result = await this.http.postData<T, Z>(url, body);
    return result;
  }
  async get<T>(url: string): Promise<T> {
    const result = await this.http.getData<T>(url);
    return result;
  }
}
