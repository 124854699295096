export const URLSMOCK = {
  local:
    ' https://8j4roplgsd.execute-api.us-east-2.amazonaws.com/dev/mock-response-handler',
  dev: ' https://8j4roplgsd.execute-api.us-east-2.amazonaws.com/dev/mock-response-handler',
  stg: 'https://4403hx3ouh.execute-api.us-east-2.amazonaws.com/stg/mock-response-handler'
};

export const URLS_FLAGS_MOCK = {
  local: 'castlemock/mock/rest/project/X0irk4/application/49tAf0/flagsMockMfes',
  dev: 'castlemock/mock/rest/project/X0irk4/application/49tAf0/flagsMockMfes',
  stg: 'castlemock/mock/rest/project/X0irk4/application/0UXeIl/FlagsMockStg'
};
