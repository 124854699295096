import { IMicrofront } from '../models/microfronts';
import { addImportMap } from './dom.service';
import { getMicrofronts } from './getmicrofronts.service';
import { addApplication, removeApplication } from './single-spa.service';
import { StateKey, getStateValue, setStateValue } from './state.service';
import { getEventFlagsMock, publishEventMock } from './mockEventsMfe.service';

export async function registerApps(env: string) {
  const appsMfes = await getMicrofronts(env);

  addImportMap(createImportMap(appsMfes['singleSpa']));
  if (env !== 'pro') {
    const flagsMock = await getEventFlagsMock();
    emitMockEventMfe(appsMfes['singleSpa'], flagsMock);
  }
  registerMfs(appsMfes['singleSpa']);
}

export const emitMockEventMfe = (mfes: IMicrofront[], flagsMock: any) => {
  mfes.forEach((mfe) => {
    if (mfe.mockEvent) {
      const isMock = flagsMock[mfe.mockEvent.nameMfe].flagMock;
      if (isMock) {
        const { mockEvent } = mfe;
        publishEventMock(
          mockEvent.mockUrl,
          mockEvent.topicPublishEvent,
          mockEvent.nameMfe
        );
      }
    }
  });
};

const createImportMap = (mfes: IMicrofront[]) => ({
  imports: mfes.reduce<Record<string, string>>((acum, mfe) => {
    acum[mfe.path] = mfe.url;
    return acum;
  }, {})
});

function registerMfs(apps: IMicrofront[]) {
  addMfsAlive(apps);
}

function addMfsAlive(apps: IMicrofront[]) {
  apps.forEach((mfe) => {
    addApplication(mfe.path, mfe.props);
  });
  const microShellApp = getStateValue<any>(StateKey.MICRO_SHELL_APP_NAME);
  setStateValue(StateKey.MICRO_SHELL_APP_ALWAYS_ALIVE, [
    ...apps.map((mf) => mf.name),
    microShellApp
  ]);
}
export function removeMfsAlwaysAlive() {
  const appsNames = getStateValue<string[]>(
    StateKey.MICRO_SHELL_APP_ALWAYS_ALIVE
  );
  const promises = appsNames.map((appName) => removeApplication(appName));
  return Promise.all(promises);
}
